import axios from 'axios';


//const URL = "http://localhost:27017";
const URL = "https://us-central1-mothermilktoolapi.cloudfunctions.net/app";
//const URL = "http://localhost:5001/mothermilktoolapi/us-central1/app";

//posts
//export const getCountries= () => axios.get(`${URL}/country`);
export const getCountries= () => axios.get(`${URL}/countries`);
export const getBreastfeedingData= async (ISO) => await axios.get(`${URL}/breastfeedingcore/${ISO}`);
export const getBreastfeedingChartData= (ISO) => axios.get(`${URL}/breastfeedingchart/${ISO}`);
export const getCountryInfo= async (ISO) => await axios.get(`${URL}/countryinfo/${ISO}`);
export const getLostMilk= async (ISO) => await axios.get(`${URL}/lostmilk/${ISO}`);
export const getCaculatorDataEntry= async (params) => await axios.post(`${URL}/dataentry`,params);
export const getIndividual= async (params) => await axios.post(`${URL}/individual`,params);