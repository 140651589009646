import {combineReducers} from "redux";
import countries from './country.js'
import breastfeedingDataReducer from "./breastfeedingData.js";
import breastfeedingChartDataReducer from "./breastfeedingChartData.js";
import countryInfoReducer from "./countryInfo.js";
import lostMilkReducer from "./lostmilk.js";
import dataEntryReducer from "./dataentry";
import individualReducer from "./individual";

export default combineReducers({
    countries,
    breastfeedingDataReducer,
    breastfeedingChartDataReducer,
    countryInfoReducer,
    lostMilkReducer,
    dataEntryReducer,
    individualReducer
})