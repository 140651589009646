// Chakra imports
import {
  Flex,
  Grid,
  Text,
} from "@chakra-ui/react";
import React from "react";
import SelectCountry from "./components/SelectCountry";
import InfoCountry from "./components/InfoCountry";
import TableData from "./components/TableData";
import ProductionAndValue from "./components/ProductionAndValue";
import SalesOverview from "./components/SalesOverview";
import LineChart from "components/Charts/LineChart";
import {useDispatch,useSelector} from 'react-redux';
import {countryState$,breastfeedingState$,countryInfoState$,breastfeedingChartState$,lostMilkState$, hideDataCountry$, loadingDataCountry$, isDisableBtnSearch$, isHideDataInfo$, isHideChart$, isHideLostMilk$} from '../../../redux/selectors';
import { productionTitle1,productionTitle2,productionTitle3,productionTitle4,productionTitle5,productionTitle6, productionData1} from "variables/general";
import * as action from '../../../redux/actions';
import IndividualCountry from './components/CountryCalculator';
import CardHeader from "components/Card/CardHeader";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";

export default function Dashboard(){
  const dispatch = useDispatch();
  const countries = useSelector(countryState$);
  const hideDataCountry = useSelector(hideDataCountry$);
  const loadingDataCountry = useSelector(loadingDataCountry$);
  const isDisableBtnSearch = useSelector(isDisableBtnSearch$);
  
 

  const breastfeedingData = useSelector(breastfeedingState$);
  const isHideChart = useSelector(isHideChart$);
  

  const countryInfo = useSelector(countryInfoState$);
  const isHideDataInfo = useSelector(isHideDataInfo$);

  const breastfeedingChartData = useSelector(breastfeedingChartState$);

  const lostMilk = useSelector(lostMilkState$);
  const isHideLostMilk = useSelector(isHideLostMilk$);
  
  React.useEffect(() => {
       dispatch(action.getCountries.getCountryRequest());
  },[dispatch]);
  const dataYearOne = breastfeedingData?.year1;
  const dataYearTwo = breastfeedingData?.year2;
  const dataYearThree = breastfeedingData?.year3;
  const chartData = breastfeedingChartData;

  return (
    <Flex direction="column" pt={{ base: "120px", md: "100px" }}>
      <IndividualCountry/>
      <SelectCountry
        country = {countries}
        selected = {countryInfo?.isocode}/>
      { (hideDataCountry !== false && isHideDataInfo === false && isHideChart === false) &&
        <Grid
          templateColumns={{ sm: "1fr", lg: "0.99fr 2fr" }}
          templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
          gap='24px'
          mb={{ lg: "26px" }}>
          <InfoCountry
            data = {countryInfo}
            count = {chartData?.linechartdata != null ? countData(chartData.linechartdata) : 0}
            />
            <SalesOverview
              title={"Prevalence of Breastfeeding"}
              chart={<LineChart data={chartData}/>}
          />
        </Grid>
      }
      {/* { breastfeedingData.country != undefined &&
        <Grid
          templateColumns={{ sm: "1fr", lg: "1fr 1fr 1fr" }}
          templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
          gap='24px'
          mb={{ lg: "26px" }}>
          <TableData
          captions={["Month", "Rate"]}
          data={dataYearOne}
          />
          <TableData
            captions={["Month", "Rate"]}
            data={dataYearTwo}
          />
          <TableData
            captions={["Month", "Rate"]}
            data={dataYearThree}
          />
        </Grid>
      } */}
      { (hideDataCountry !== false && isHideLostMilk === false) &&
        <Grid mt="24px">
          <Text p='22px' fontSize='lg' fontWeight="bold">Milk Production, Value and Loss</Text>
          <ProductionAndValue
            captions={productionTitle1}
            data={lostMilk?.total_3y == null ? productionData1 : lostMilk?.total_3y}
            rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
            source={lostMilk?.source == null ? "" : lostMilk?.source}
            currency = {countryInfo?.currencyCode == null ? "" : countryInfo?.currencyCode}
            header = {"All three years (0-35.9 months)"}
          />
          <ProductionAndValue
            captions={productionTitle2}
            data={lostMilk?.total_0m_6m == null ? productionData1 : lostMilk?.total_0m_6m}
            rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
            source={lostMilk?.source == null ? "" : lostMilk?.source}
            currency = {countryInfo?.currencyCode == null ? "" : countryInfo?.currencyCode}
            header = {"The first six months (0-5.9 months)"}
          />
          <ProductionAndValue
            captions={productionTitle3}
            data={lostMilk?.total_6m_24m == null ? productionData1 : lostMilk?.total_6m_24m}
            rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
            source={lostMilk?.source == null ? "" : lostMilk?.source}
            currency = {countryInfo?.currencyCode == null ? "" : countryInfo?.currencyCode}
            header = {"From 6 to 23.9 months"}
          />
          <ProductionAndValue
            captions={productionTitle4}
            data={lostMilk?.total_1sty == null ? productionData1 : lostMilk?.total_1sty}
            rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
            source={lostMilk?.source == null ? "" : lostMilk?.source}
            currency = {countryInfo?.currencyCode == null ? "" : countryInfo?.currencyCode}
            header = {"The first year (0-11.9 months)"}
          />
          <ProductionAndValue
            captions={productionTitle5}
            data={lostMilk?.total_2ndy == null ? productionData1 : lostMilk?.total_2ndy}
            rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
            source={lostMilk?.source == null ? "" : lostMilk?.source}
            currency = {countryInfo?.currencyCode == null ? "" : countryInfo?.currencyCode}
            header = {"The second year (12-23.9 months)"}
          />
          <ProductionAndValue
            captions={productionTitle6}
            data={lostMilk?.total_3rdy == null ? productionData1 : lostMilk?.total_3rdy}
            rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
            source={lostMilk?.source == null ? "" : lostMilk?.source}
            currency = {countryInfo?.currencyCode == null ? "" : countryInfo?.currencyCode}
            header = {"The third year (24-35.9 months)"}
          />
        </Grid>
      }
      
    </Flex>
  );
}

function countData(data){
  if (data == null || data == undefined) return 0;
  let count = 0;
  data[0]?.data?.forEach(element => {
    if (element != 0){
      count ++;
    }
  });
  return count;
}