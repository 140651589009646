import { Heading, Image, Text, Grid, Center, Box, Link } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ChildAndDad from "assets/img/ChildrenAndDad.jpg";
import { countryCalculatorOverViewF, countryCalculatorOverViewL } from "../../../../variables/general";
import React from "react";

const CacolatorItem = ({ title, link, img }) => {
  return (
    <Card>
      <Grid spacing={16} justify="flex-start">
        <Grid order={{ sm: "2", lg: "2" }}>
          <Link href={link}>
            <Heading as='h3' size='lg'>{title}</Heading>
          </Link>
        </Grid>
        <Grid
          order={{ sm: "1", lg: "1" }}
          sx={{ display: { lg: 'none', sm: 'block' } }}
          mt="20px"
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'
          mb={{ sm: "26px" }}>
          <Link href={link}>
            <Image src={img} alt='img2' sx={{ display: { sm: 'block', lg: 'none' } }} />
          </Link>
        </Grid>
      </Grid>

      <CardBody mt={{ sm: "0px", lg: "30px" }}>
        <Grid
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'
          mb={{ lg: "26px" }}>
          <Link href={link}>
            <Image src={img} alt='img2' sx={{ display: { sm: 'none', lg: 'block' } }} />
          </Link>
          <Box fontSize="15px">
            <Text>
              {countryCalculatorOverViewF}
            </Text>
            <Text mt="10px">
              {countryCalculatorOverViewL}
            </Text>
          </Box>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default CacolatorItem;
