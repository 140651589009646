import {
  Grid,
  Box
} from "@chakra-ui/react";
import React from "react";
import AboutTool from "./components/AboutTool";
import AboutToolImage from "./components/AboutToolImage";
import CountryCaculatorItem from "./components/CountryCaculatorItem";
import IndividualCaculatorItem from "./components/IndividualCaculatorItem";
import InfoTool from "./components/InfoTool";
import AboutToolItem from "./components/AboutToolItem";
import Capture1 from "assets/img/CountryCalculator.jpg";
import Capture2 from "assets/img/IndividualCalculator.jpg";
import ExperienceAndperspective from "./components/ExperienceAndperspective";

export default function Dashboard() {

  return (
    <Grid
      mt="100px"
      gap='24px'
      mb={{ lg: "26px" }}>
      <InfoTool />
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
        templateRows={{ sm: "1fr", lg: "1fr" }}
        gap='24px'>
        <CountryCaculatorItem title="Country calculator" link="#/countrycalculator" img={Capture1} />
        <IndividualCaculatorItem title="Individual calculator" link="#/individualcalculator" img={Capture2} />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
        templateRows={{ sm: "1fr", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <AboutToolItem title="Resources" link="#/" />
        <ExperienceAndperspective title="Feedback" link="#/" />
      </Grid>
    </Grid>
  );
}
