// Chakra imports
import React from "react";
import {
  Flex,
  Grid,
  Button,
  Center,
  Select,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Text,
  Image
} from "@chakra-ui/react";
import TableData from "./components/TableData";
import {countryState$, individualState$, isDisableBtnSearch$, isHideIndividual$} from '../../../redux/selectors';
import {data1, data2, data3} from '../../../variables/general';
import {useDispatch,useSelector} from 'react-redux';
import {useState} from "react";
import * as action from '../../../redux/actions';
import { dataEntryDefault, ListTitle1} from "variables/general";
import ProductionAndValue from "./components/ProductionAndValue";
import { useTranslation } from "react-i18next";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import IndividualCalculator from "./components/IndividualCalculator";
import gif from "assets/img/loading.gif";

export default function Dashboard() {
  const { t } = useTranslation();
  const [countryValue, setCountry] = useState("");
  const [volume, setVolume] = useState(0);
  const [isShow, setIsShow] = useState(false);
  const countries = useSelector(countryState$);
  const individual = useSelector(individualState$);
  const isHideIndividual = useSelector(isHideIndividual$);
  const isDisableBtnSearch = useSelector(isDisableBtnSearch$);
  const dispatch = useDispatch();
  React.useEffect(() => {
        dispatch(action.getCountries.getCountryRequest());
  },[dispatch]);
  //var dataInput = dataEntryDefault;
  const onChangeInput = React.useCallback((value) => {
    setVolume(value);
  },[]);
  const sendData = React.useCallback((ISO, vol) => {
    let strjson = '{"ISO":"' + ISO + '", "month":' + vol + '}';
    let json = JSON.parse(strjson);
    dispatch(action.getIndividualDatas.getIndividualRequest(json));
  },[dispatch]);
  return (
    <Flex direction="column" pt={{ base: "120px", md: "100px" }}>
      <IndividualCalculator/>
      <Card>
        <CardBody>
        <Grid
          templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
          gap='24px'>
            <Grid
              templateColumns={{ sm: "1fr" }}>
              <Text lineHeight="40px">
                {t("label.selectCountry")}
              </Text>
              <Select placeholder=  {t("label.selectCountry")}
                disabled={isDisableBtnSearch}
                h='40px'
                minW="250px"
                mr="10px"
                onChange={(e) =>{setCountry(e.currentTarget.value); setIsShow(false)}}
                fontSize="14px">
                {countries?.map((value, id) => {
                  return (
                    <option key={id} value={value.ISO}>{value.Country}</option>
                  );
                })}
              </Select>
            </Grid>
            <Grid
              templateColumns={{ sm: "1fr"}}>
              <Text lineHeight="40px">
                For how many months have you breastfed the child?
              </Text>
              <NumberInput onChange={(e)=>onChangeInput(e)} defaultValue={0} min={0} max={36}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper />
                  <NumberDecrementStepper />
                </NumberInputStepper>
              </NumberInput>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
      <Grid
        mt="20px"
        templateColumns={{ sm: "1fr", lg: "1fr" }}
        gap='24px'>
          <Center>
          {countryValue != '' ? 
            <Button
              className="btn-individual-caculator"
              disabled={isDisableBtnSearch}
              onClick={()=>{sendData(countryValue, volume); setIsShow(true)}}
              background="#BE830E"
              minW="200px">
              <Text>Calculate </Text>
              <Image ml='5px' display={isHideIndividual === true ? 'block' : 'none' } src={gif} height='20px' alt='Loading'/>
            </Button>
          :
            <Button
              className="btn-individual-caculator"
              background="#BE830E"
              disabled
              minW="200px">
              <Text>Calculate</Text>
            </Button>
          }
          </Center>
      </Grid>
      
      {/* <Grid
        mt = "24px"
        templateColumns={{ sm: "1fr", lg: "1fr 1fr 1fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb={{ lg: "26px" }}>
        <TableData
        captions={["Month", "Rate"]}
        data={data1}
        keydata = "1"
        getData = {getData}
        datadefault = {dataEntryDefault[0].value}
        />
        <TableData
          captions={["Month", "Rate"]}
          data={data2}
          keydata = "2"
          getData = {getData}
          datadefault = {dataEntryDefault[1].value}
        />
        <TableData
          captions={["Month", "Rate"]}
          data={data3}
          keydata = "3"
          getData = {getData}
          datadefault = {dataEntryDefault[2].value}
        />
      </Grid> */}
      {(isShow === true && isHideIndividual === false) &&
        <Grid mt="24px">
          <ProductionAndValue
            captions={ListTitle1}
            data={individual?.rate == null ? 1: individual}
          />
        </Grid>
      }
    </Flex>
  );
}