import { takeLatest ,call,put} from 'redux-saga/effects';
import * as actions from '../actions';
import * as api from '../../api/index.js';

function* fetchCountrySaga() {
    try{
        const countries = yield call(api.getCountries);
        yield put(actions.getCountries.getCountrySuccess(countries.data));
    }catch (err){
        console.log(err)
        yield put(actions.getCountries.getCountryError(err));
    }    
}

function* fetchBreastfeedingSaga(action) {
    try{
        const breastfeedings = yield call(api.getBreastfeedingData,action.payload);
        yield put(actions.getBreastfeedingDatas.getBreastfeedingDataSuccess(breastfeedings.data));
    }catch (err){
        console.log(err)
        yield put(actions.getBreastfeedingDatas.getBreastfeedingDataError(err));
    }    
}

function* fetchBreastfeedingChartSaga(action) {
    try{
        const breastfeedingchart = yield call(api.getBreastfeedingChartData,action.payload);
        yield put(actions.getBreastfeedingChartDatas.getBreastfeedingChartDataSuccess(breastfeedingchart.data));
    }catch (err){
        console.log(err)
        yield put(actions.getBreastfeedingChartDatas.getBreastfeedingChartDataError(err));
    }    
}

function* fetchCountryInfoSaga(action) {
    try{
        const countryInfo = yield call(api.getCountryInfo,action.payload);
        yield put(actions.getCountryInfo.getCountryInfoSuccess(countryInfo.data));
    }catch (err){
        console.log(err)
        yield put(actions.getCountryInfo.getCountryInfoError(err));
    }
}

function* fetchLostMilkSaga(action) {
    try{
        const lostmilk = yield call(api.getLostMilk,action.payload);
        yield put(actions.getLostMilkDatas.getLostMilkSuccess(lostmilk.data));
    }catch (err){
        console.log(err)
        yield put(actions.getLostMilkDatas.getLostMilkError(err));
    }    
}

function* fetchDataEntrySaga(action) {
    try{
        const dataentry = yield call(api.getCaculatorDataEntry,action.payload);
        yield put(actions.getDataEntryDatas.getDataEntrySuccess(dataentry.data));
    }catch (err){
        console.log(err)
        yield put(actions.getDataEntryDatas.getDataEntryError(err));
    }    
}

function* fetchIndividualSaga(action) {
    try{
        const individual = yield call(api.getIndividual,action.payload);
        yield put(actions.getIndividualDatas.getIndividualSuccess(individual.data));
    }catch (err){
        console.log(err)
        yield put(actions.getIndividualDatas.getIndividualError(err));
    }    
}


function* mySaga() {
    yield takeLatest(actions.getCountries.getCountryRequest,fetchCountrySaga)
    yield takeLatest(actions.getBreastfeedingDatas.getBreastfeedingDataRequest,fetchBreastfeedingSaga)
    yield takeLatest(actions.getBreastfeedingChartDatas.getBreastfeedingChartDataRequest,fetchBreastfeedingChartSaga)
    yield takeLatest(actions.getCountryInfo.getCountryInfoRequest,fetchCountryInfoSaga)
    yield takeLatest(actions.getLostMilkDatas.getLostMilkRequest,fetchLostMilkSaga),
    yield takeLatest(actions.getDataEntryDatas.getDataEntryRequest,fetchDataEntrySaga),
    yield takeLatest(actions.getIndividualDatas.getIndividualRequest,fetchIndividualSaga)
}

export default mySaga;