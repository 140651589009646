import {createActions, createAction} from 'redux-actions';

export const getType = (reduxAction) =>{
    return reduxAction().type;
}

//countries
export const getCountries = createActions({
    getCountryRequest: undefined,
    getCountrySuccess: (payload) => payload,
    getCountryError: (err) => err,
})

export const getBreastfeedingDatas = createActions({
    getBreastfeedingDataRequest: (payload) => payload,
    getBreastfeedingDataSuccess: (payload) => payload,
    getBreastfeedingDataError: (err) => err,
})

export const getBreastfeedingChartDatas = createActions({
    getBreastfeedingChartDataRequest: (payload) => payload,
    getBreastfeedingChartDataSuccess: (payload) => payload,
    getBreastfeedingChartDataError: (err) => err,
})

export const getCountryInfo = createActions({
    getCountryInfoRequest: (payload) => payload,
    getCountryInfoSuccess: (payload) => payload,
    getCountryInfoError: (err) => err,
})

export const getLostMilkDatas = createActions({
    getLostMilkRequest: (payload) => payload,
    getLostMilkSuccess: (payload) => payload,
    getLostMilkError: (err) => err,
})

export const getDataEntryDatas = createActions({
    getDataEntryRequest: (payload) => payload,
    getDataEntrySuccess: (payload) => payload,
    getDataEntryError: (err) => err,
})

export const getIndividualDatas = createActions({
    getIndividualRequest: (payload) => payload,
    getIndividualSuccess: (payload) => payload,
    getIndividualError: (err) => err,
})

export const isShow = createAction('IS_SHOW');
export const isHide = createAction('IS_HIDE');

export const isLoading = createAction('IS_LOADING');