import {
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import { useTranslation } from "react-i18next";

function BillingRow(props) {
  const labelColor = useColorModeValue("#BE830E", "#BE830E");
  const textColor = useColorModeValue("gray.700", "white");
  const {data, count} = props;
  const { t } = useTranslation();
  return (
    <Card id='info-country-caculator' mt="10px" mb={{ sm: "26px", lg: "0px" }}>
      <CardHeader>
        <Flex direction='column' alignSelf='flex-start'>
          <Text fontSize='lg' color={textColor} fontWeight='bold'>
            {data?.country}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody mt="22px">
        <Flex justify="space-between" w="100%">
          <Flex direction="column">
            <Text color={labelColor} fontSize="sm" fontWeight="semibold">
            {t("infoCountry.ISOCode")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.isocode}
              </Text>
            </Text>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.Region")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.region}
              </Text>
            </Text>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.IncomeClass")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.incomeClass}
              </Text>
            </Text>
            <br/>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.DataCollectionYear")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.dataYear}
              </Text>
            </Text>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.DataSource")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.dataSource}
              </Text>
            </Text>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {"Number of data points:"}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {count}
              </Text>
            </Text>
            <br/>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.Livebirths")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.noOfLivebirths?.toLocaleString()}
              </Text>
            </Text>
            <Text color={labelColor} fontSize="sm" fontWeight="semibold">
            {t("infoCountry.Year")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.year}
              </Text>
            </Text>
            <br/>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.ExchangeRate")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {parseFloat(data?.rate)?.toLocaleString()}
              </Text>
            </Text>
            <Text color={labelColor} fontSize="sm" fontWeight="semibold">
            {t("infoCountry.Currency")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.currency}
              </Text>
            </Text>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.CurrencyCode")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.currencyCode}
              </Text>
            </Text>
            <Text color={labelColor}  fontSize="sm" fontWeight="semibold">
            {t("infoCountry.CurrencyRateDate")}{" "}
              <Text as="span" color={textColor} fontWeight="normal">
                {data?.currencyRateDate}
              </Text>
            </Text>
          </Flex>
        </Flex>
      </CardBody>
    </Card>

  );
}

export default BillingRow;