import Introduction from "views/Introduction/Introduction";
import Feedback from "views/Introduction/Feedback";
import IndividualCalculator from "views/MothersMilkTool/IndividualCalculator";
import CountryCalculator from "views/MothersMilkTool/CountryCalculator";
import DataEntry from "views/MothersMilkTool/DataEntry";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    name: "MOTHERS' MILK TOOL",
    category: "category",
    state: "pageCollapse",
    views: [
      {
        path: "/countrycalculator",
        name: "Country calculator",
        component: CountryCalculator,
        layout: "",
      },
      {
        path: "/individualcalculator",
        name: "Individual calculator",
        component: IndividualCalculator,
        layout: "",
      },
      {
        path: "/dataentry",
        name: "Data Entry",
        component: DataEntry,
        layout: "",
      }
    ],
  },
  {
    name: "INTRODUCTION PAGES",
    category: "category",
    state: "pageCollapse",
    views: [
      // {
      //   path: "/feedback",
      //   name: "Feedback",
      //   component: Feedback,
      //   layout: "",
      // },
      {
        path: "/*",
        name: "Introduction",
        component: Introduction,
        layout: "",
      }
    ],
  }
];
export default dashRoutes;
