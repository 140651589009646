import { INIT_STATE } from "../../constants.js";
import { getDataEntryDatas, getType} from "../actions";

export default function dataEntryReducer(state = INIT_STATE.dataEntry, action) {
  switch (action.type) {
    case getType(getDataEntryDatas.getDataEntryRequest):
      console.log("request")
      return {
        ...state,
      };
    case getType(getDataEntryDatas.getDataEntrySuccess):
      console.log("success")
      return {
        ...state,
        data: action.payload,
      };
    case getType(getDataEntryDatas.getDataEntryError):
      console.log("error")
      return {
        ...state,
      };  
    default:
      return state;
  }
}
