// Chakra imports
import {
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    Center,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card.js";
  import React from "react";
  
  const ProductionAndValue = ({captions, data, mgl = "0px", mgr = "0px"}) => {
  const textColor = useColorModeValue("#BE830E", "white");
  
    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }} mb="24px" ml = {mgl} mr={mgr}>
        <Table variant='simple' mb="20px">
          <Thead>
            <Tr>
              {captions.map((caption, idx) => {
                return (
                  <Th key={idx} pl={idx == 0 ? '0px' : null} textTransform="none" whiteSpace="nowrap">
                    { idx == 0 ?
                      (
                        <Text fontSize="14px" color='#BE830E' >{caption.title}</Text>
                      )
                      :
                      (
                        <Center><Text fontSize="14px" color='#BE830E' >{caption.title}</Text></Center>
                      )
                    }
                    <Center><Text fontSize="12px" height='16px' fontWeight="light">{ idx == 3 ? 'in ' + data?.CurrencyCode : caption.note}</Text></Center>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
              <Tr fontSize="14px">
                <Td pl="0px">1. Actual production of breastmilk</Td>
                <Td><Center>{data?.R45 == null ? 0 : data?.R45?.toLocaleString()}</Center></Td>
                <Td><Center>{data?.X45 == null ? 0 : data?.X45?.toLocaleString()}</Center></Td>
                <Td><Center>{data?.AA45 == null ? 0 : data.AA45?.toLocaleString()}</Center></Td>
              </Tr>
          </Tbody>
        </Table>
      </Card>
    );
  };
  
  export default ProductionAndValue;
  