// Chakra imports
import {
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    Center,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card.js";
  import React from "react";
  
  const ProductionAndValue = ({captions, data, rate, source, currency, mgl = "0px", mgr = "0px"}) => {
  const textColor = useColorModeValue("#BE830E", "white");
  
    return (
      <Card overflowX='auto' minW="650px" mb="24px" ml = {mgl} mr={mgr}>
        <Table variant='simple' mb="20px">
          <Thead>
            <Tr>
              {captions.map((caption, idx) => {
                return (
                  <Th key={idx} textTransform="none" whiteSpace="nowrap">
                    <Center><Text fontSize="14px" color={textColor} >{caption.title}</Text></Center>
                    <Center><Text fontSize="12px" fontWeight="light">{caption.note} {idx == 3 ? currency : ''}</Text></Center>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, id) => {
              return (
                <Tr key={id} fontSize="14px">
                  <Td pl="0px">{row.id}</Td>
                  <Td><Center>{row.volume =='NaN%' ? 'N/A' : (row.volume.toLocaleString())}</Center></Td>
                  <Td><Center>{row.valueUSD?.toLocaleString()}</Center></Td>
                  <Td><Center>{row.valueAustralianDolor?.toLocaleString()}</Center></Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* <Center><Text fontWeight="light" fontSize="12px">Exchange rate US$ 1: 1.39 Australian Dollar</Text></Center> */}
        {/* <Center><Text fontWeight="light" fontSize="12px">Data source: Partial data, Source: National survey</Text></Center> */}
      </Card>
    );
  };
  
  export default ProductionAndValue;
  