// Chakra imports
import { useColorModeValue,Select ,Text, Button, Grid, Image, Box } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, {useState} from "react";
import {useDispatch,useSelector} from 'react-redux';
import * as action from '../../../../redux/actions';
import { useTranslation } from "react-i18next";
import {hideDataCountry$, isDisableBtnSearch$, isHideLostMilk$, isHideDataInfo$, isHideChart$} from '../../../../redux/selectors';
import gif from "assets/img/loading.gif";


const SelectCountry = ({country, selected}) => {
  
  const dispatch = useDispatch();
  const textColor = useColorModeValue("gray.700", "white");
  const [countryValue, setCountry] = useState("");
  const { t } = useTranslation();
  const isHideLostMilk = useSelector(isHideLostMilk$);
  const isHideDataInfo = useSelector(isHideDataInfo$);
  const isHideChart = useSelector(isHideChart$);

  // const hideDataCountry = useSelector(hideDataCountry$);
  const isDisableBtnSearch = useSelector(isDisableBtnSearch$);
  const onClickSearch = React.useCallback((ISO) => {
    if (ISO == '') {
      return;
    };
    dispatch(action.getBreastfeedingDatas.getBreastfeedingDataRequest(ISO));
    dispatch(action.getBreastfeedingChartDatas.getBreastfeedingChartDataRequest(ISO));
    dispatch(action.getCountryInfo.getCountryInfoRequest(ISO));
    dispatch(action.getLostMilkDatas.getLostMilkRequest(ISO));
    dispatch(action.isShow());
  },[dispatch]);

  const onChangeSelectCountry = React.useCallback((e) => {
    setCountry(e.currentTarget.value);
    dispatch(action.isHide());
  },[dispatch]);
  return (
    <Card id="select-country-caculator">
      <CardHeader>
        <Text color={textColor} fontWeight='bold' pb='.5rem'>
          {t("label.selectCountry")}
        </Text>
      </CardHeader>
      <CardBody mt="10px">
        <Grid
          templateColumns={{ sm: "1fr", lg: "1fr 1fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'>
            <Select
                disabled={isDisableBtnSearch}
                placeholder='Select Country'
                h='40px'
                minW="200px"
                mr="10px"
                onChange={(e) =>onChangeSelectCountry(e)}
                //value = {countryValue == '' ? (selected == null ? 'AFG' : selected) : countryValue}
                fontSize="14px">
                {country?.map((value, id) => {
                  return (
                    <option key={id} value={value.ISO}>{value.Country}</option>
                  );
                })}
            </Select>
            
            <Button
                disabled={isDisableBtnSearch}
                color="#FFFFFF"
                background='#BE830E'
                opacity="0.75"
                _hover={{ background:"#BE830E", color:"#FFFFFF", opacity:1}}
                minW='110px'
                h='40px'
                fontSize='xs'
                px='1.5rem'
                onClick={()=>onClickSearch(countryValue)}
                borderRadius="5px">
                <Text m="0px 10px">Calculate</Text>
                <Image display={isHideLostMilk === true && isHideDataInfo === true && isHideChart === true ? 'block' : 'none' } src={gif} height='20px' alt='Loading'/>
            </Button>
              
            {/* <Link href="#/admin/dataentry">
            <Button
                color="#FFFFFF"
                background='#BE830E'
                opacity="0.75"
                _hover={{ background:"#BE830E", color:"#FFFFFF", opacity:1}}
                minW='110px'
                h='40px'
                fontSize='xs'
                px='1.5rem'
                borderRadius="5px">
                <Text m="0px 10px">Data Entry</Text>
            </Button>
            </Link> */}
        </Grid>
      </CardBody>
    </Card>
  );
};

export default SelectCountry;
