
export const INIT_STATE = {
    countries:{
        data:[],
        isHide: false,
        isLoading: true,
        isDisableBtnSearch: true,
    },
    breastfeedings:{
        data:{}
    },
    breastfeedingchart:{
        data:{},
        isHideChart: null
    },
    countryInfo:{
        data:{},
        isHideDataInfo: null,
    },
    lostMilk :{
        data:{},
        isHideLostMilk: null
    },
    dataEntry :{
        data:{}
    },
    individual :{
        data:{},
        isHideIndividual: null
    }
}