export const lineChartData = [
  {
    name: "Data",
    data: [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,],
  }
];

export const lineChartOptions = {
  chart: {
    toolbar: {
      show: false,
    },
  },
  tooltip: {
    theme: "dark",
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  xaxis: {
    categories: [
      1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36
    ],
    labels: {
      style: {
        colors: "#BE830E",
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    forceNiceScale: false,
    max: 100,
    min: 0,
    tickAmount: 5,
    labels: {
      style: {
        colors: "#BE830E",
        fontSize: "12px",
      },
      formatter: function (val) {
        return Math.abs(Math.round(val)) + "%"
      }
    },
  },
  legend: {
    show: false,
  },
  fill: {
    type: "gradient",
    gradient: {
      shade: "light",
      type: "vertical",
      shadeIntensity: 0.5,
      gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
      inverseColors: true,
      opacityFrom: 0.8,
      opacityTo: 0,
      stops: [],
    },
    colors: ["#BE830E", "#BE830E"],
  },
  colors: ["#BE830E", "#BE830E"],
};
