// Custom icons
import {
  AdobexdLogo,
  AtlassianLogo,
  InvisionLogo,
  JiraLogo,
  SlackLogo,
  SpotifyLogo,
} from "components/Icons/Icons.js";
import { AiOutlineExclamation } from "react-icons/ai";
import {
  FaArrowDown,
  FaArrowUp,
  FaBell,
  FaCreditCard,
  FaFilePdf,
  FaHtml5,
  FaShoppingCart,
} from "react-icons/fa";
import { SiAmazon, SiDropbox } from "react-icons/si";

export const dataCaculator = [
  {
    year1: "0<1",
    value1: "100%",
    year2: "12<13",
    value2: "100%",
    year3: "24<25",
    value3: "100%",
  },
  {
    year1: "1<2",
    value1: "100%",
    year2: "13<14",
    value2: "96%",
    year3: "25<26",
    value3: "74%",
  },
  {
    year1: "2<3",
    value1: "98%",
    year2: "14<15",
    value2: "95%",
    year3: "26<27",
    value3: "67%",
  },
  {
    year1: "3<4",
    value1: "100%",
    year2: "15<16",
    value2: "94%",
    year3: "27<28",
    value3: "62%",
  },
  {
    year1: "4<5",
    value1: "98%",
    year2: "16<17",
    value2: "94%",
    year3: "28<29",
    value3: "64%",
  },
  {
    year1: "5<6",
    value1: "99%",
    year2: "17<18",
    value2: "95%",
    year3: "29<30",
    value3: "54%",
  },
  {
    year1: "6<7",
    value1: "98%",
    year2: "18<19",
    value2: "91%",
    year3: "30<31",
    value3: "47%",
  },
  {
    year1: "7<8",
    value1: "98%",
    year2: "19<20",
    value2: "94%",
    year3: "31<32",
    value3: "46%",
  },
  {
    year1: "8<9",
    value1: "98%",
    year2: "20<21",
    value2: "91%",
    year3: "32<33",
    value3: "48%",
  },
  {
    year1: "9<10",
    value1: "97%",
    year2: "21<22",
    value2: "89%",
    year3: "33<34",
    value3: "46%",
  },
  {
    year1: "10<11",
    value1: "97%",
    year2: "22<23",
    value2: "89%",
    year3: "34<35",
    value3: "39%",
  },
  {
    year1: "11<12",
    value1: "97%",
    year2: "23<24",
    value2: "84%",
    year3: "35<36",
    value3: "41%",
  },
];

export const data1 = [
  {
    data: "M0",
    year: "0<1",
    value: "100%",
  },
  {
    data: "M1",
    year: "1<2",
    value: "75%",
  },
  {
    data: "M2",
    year: "2<3",
    value: "73%",
  },
  {
    data: "M3",
    year: "3<4",
    value: "70%",
  },
  {
    data: "M4",
    year: "4<5",
    value: "69%",
  },
  {
    data: "M5",
    year: "5<6",
    value: "63%",
  },
  {
    data: "M6",
    year: "6<7",
    value: "60%",
  },
  {
    data: "M7",
    year: "7<8",
    value: "42%",
  },
  {
    data: "M8",
    year: "8<9",
    value: "42%",
  },
  {
    data: "M9",
    year: "9<10",
    value: "42%",
  },
  {
    data: "M10",
    year: "10<11",
    value: "42%",
  },
  {
    data: "M11",
    year: "11<12",
    value: "42%",
  }
];
export const data2 = [
  {
    data: "M12",
    year: "12<13",
    value: "42%",
  },
  {
    data: "M13",
    year: "13<14",
    value: "18%",
  },
  {
    data: "M14",
    year: "14<15",
    value: "18%",
  },
  {
    data: "M15",
    year: "15<16",
    value: "18%",
  },
  {
    data: "M16",
    year: "16<17",
    value: "18%",
  },
  {
    data: "M17",
    year: "17<18",
    value: "18%",
  },
  {
    data: "M18",
    year: "18<19",
    value: "18%",
  },
  {
    data: "M19",
    year: "19<20",
    value: "7%",
  },
  {
    data: "M20",
    year: "20<21",
    value: "7%",
  },
  {
    data: "M21",
    year: "21<22",
    value: "7%",
  },
  {
    data: "M22",
    year: "22<23",
    value: "7%",
  },
  {
    data: "M23",
    year: "23<24",
    value: "7%",
  }
];

export const data3 = [
  {
    data: "M24",
    year: "24<25",
    value: "0%"
  },
  {
    data: "M25",
    year: "25<26",
    value: "0%"
  },
  {
    data: "M26",
    year: "26<27",
    value: "0%"
  },
  {
    data: "M27",
    year: "27<28",
    value: "0%"
  },
  {
    data: "M28",
    year: "28<29",
    value: "0%"
  },
  {
    data: "M29",
    year: "29<30",
    value: "0%"
  },
  {
    data: "M30",
    year: "30<31",
    value: "0%"
  },
  {
    data: "M31",
    year: "31<32",
    value: "0%"
  },
  {
    data: "M32",
    year: "32<33",
    value: "0%"
  },
  {
    data: "M33",
    year: "34<35",
    value: "0%"
  },
  {
    data: "M34",
    year: "34<35",
    value: "0%"
  },
  {
    data: "M35",
    year: "35<36",
    value: "0%"
  }
];

export const productionTitle1 = [
  {
    title: "Total for 3 years",
    note: '', //"(Estimate for 24 data entries)",
  },
  {
    title: "Milk volume",
    note: "in million liters",
  },
  {
    title: "Value",
    note: "in million USD",
  },
  {
    title: "Value",
    note: "in million ",
  },
];

export const productionTitle2 = [
  {
    title: "Total for 0 to <6 months",
    note: '', //"(Estimate for 6 data entries)",
  },
  {
    title: "Milk volume",
    note: "in million liters",
  },
  {
    title: "Value",
    note: "in million USD",
  },
  {
    title: "Value",
    note: "in million ",
  },
];

export const productionTitle3 = [
  {
    title: "Total for 6 to <24 months",
    note: '', //"(Estimate for 18 data entries)",
  },
  {
    title: "Milk volume",
    note: "in million liters",
  },
  {
    title: "Value",
    note: "in million USD",
  },
  {
    title: "Value",
    note: "in million ",
  },
];

export const productionTitle4 = [
  {
    title: "Total for 1st year",
    note: '', //"(Estimate for 12 data entries)",
  },
  {
    title: "Milk volume",
    note: "in million liters",
  },
  {
    title: "Value",
    note: "in million USD",
  },
  {
    title: "Value",
    note: "in million ",
  },
];

export const productionTitle5 = [
  {
    title: "Total for 2nd year",
    note: '', //"(Estimate for 12 data entries)",
  },
  {
    title: "Milk volume",
    note: "in million liters",
  },
  {
    title: "Value",
    note: "in million USD",
  },
  {
    title: "Value",
    note: "in million ",
  },
];

export const productionTitle6 = [
  {
    title: "Total for 3rd year",
    note: '', //"(Estimate for 12 data entries)",
  },
  {
    title: "Milk volume",
    note: "in million liters",
  },
  {
    title: "Value",
    note: "in million USD",
  },
  {
    title: "Value",
    note: "in million ",
  },
];

export const productionData1 = [
  {
    id: "1. Actual annual production of breastmilk",
    volume: "0",
    valueUSD: "0",
    valueAustralianDolor: "0"
  },
  {
    id: "2. Potential production of breastmilk",
    volume: "0",
    valueUSD: "0",
    valueAustralianDolor: "0"
  },
  {
    id: "3. Lost breastmilk",
    volume: "0",
    valueUSD: "0",
    valueAustralianDolor: "0"
  },
  {
    id: "4. Percent lost",
    volume: "0%",
    valueUSD: "",
    valueAustralianDolor: ""
  }
];

export const countryData = [
"Afghanistan",
"Albania",
"Algeria",
"American samoa",
"Andorra",
"Angola",
"Anguilla",
"Antigua and Barbuda",
"Argentina",
"Armenia",
"Aruba",
"Australia",
"Austria",
"Azerbaijan",
"Bahamas",
"Bahrain",
"Bangladesh",
"Barbados",
"Belarus",
"Belgium",
"Belize",
"Benin",
"Bermuda",
"Bhutan",
"Bolivia (Plurinational State of)",
"Bosnia and Herzegovina",
"Botswana",
"Brazil",
"British Virgin Island",
"Brunei Darussalam",
"Bulgaria",
"Burkina Faso",
"Burundi",
"Cabo Verde",
"Cambodia",
"Cameroon",
"Canada",
"Cayman Island",
"Central African Republic",
"Chad",
"Channel Islands",
"Chile",
"China",
"China, Hong Kong SAR",
"China, Macao SAR",
"China, Taiwan Province of China",
"Colombia",
"Comoros",
"Congo",
"Cook Island",
"Costa Rica",
"Côte d'Ivoire",
"Croatia",
"Cuba",
"Curaçao",
"Cyprus",
"Czechia",
"Dem. People's Republic of Korea",
"Democratic Republic of the Congo",
"Denmark",
"Djibouti",
"Dominica",
"Dominican Republic",
"Ecuador",
"Egypt",
"El Salvador",
"Equatorial Guinea",
"Eritrea",
"Estonia",
"Ethiopia",
"Falkand Island",
"Fiji",
"Finland",
"France",
"French Guiana",
"French Polynesia",
"Gabon",
"Gambia",
"Georgia",
"Germany",
"Ghana",
"Gibraltar",
"GLOBAL",
"Greece",
"Greenland",
"Grenada",
"Guadeloupe",
"Guam",
"Guatemala",
"Guinea",
"Guinea-Bissau",
"Guyana",
"Haiti",
"Honduras",
"Hungary",
"Iceland",
"India",
"Indonesia",
"Iran (Islamic Republic of)",
"Iraq",
"Ireland",
"Israel",
"Italy",
"Jamaica",
"Japan",
"Jordan",
"Kazakhstan",
"Kenya",
"Kiribati",
"Kuwait",
"Kyrgyzstan",
"Lao People's Democratic Republic",
"Latvia",
"Lebanon",
"Lesotho",
"Liberia",
"Libya",
"Liechtenstein",
"Lithuania",
"Luxembourg",
"Madagascar",
"Malawi",
"Malaysia",
"Maldives",
"Mali",
"Malta",
"Marshall Islands",
"Martinique",
"Mauritania",
"Mauritius",
"Mayotte",
"Mexico",
"Micronesia (Fed. States of)",
"Monaco",
"Mongolia",
"Montenegro",
"Montserrat",
"Morocco",
"Mozambique",
"Myanmar",
"Namibia",
"Nauru",
"Nepal",
"Netherlands",
"New Caledonia",
"New Zealand",
"Nicaragua",
"Niger",
"Nigeria",
"Niue",
"North Macedonia",
"Norfilk Island",
"Northern Mariana Island",
"Norway",
"Oman",
"Pakistan",
"Palau",
"Panama",
"Papua New Guinea",
"Paraguay",
"Peru",
"Philippines",
"Pitcarin",
"Poland",
"Portugal",
"Puerto Rico",
"Qatar",
"Republic of Korea",
"Republic of Moldova",
"Réunion",
"Romania",
"Russian Federation",
"Rwanda",
"Saint Kitts and Nevis",
"Saint Lucia",
"Saint Vincent and the Grenadines",
"Samoa",
"San Marino",
"Sao Tome and Principe",
"Saudi Arabia",
"Senegal",
"Serbia",
"Seychelles",
"Sierra Leone",
"Singapore",
"Slovakia",
"Slovenia",
"Solomon Islands",
"Somalia",
"South Africa",
"South Sudan",
"Spain",
"Sri Lanka",
"State of Palestine",
"Sudan",
"Suriname",
"Swaziland",
"Sweden",
"Switzerland",
"Syrian Arab Republic",
"Tajikistan",
"Thailand",
"Timor-Leste",
"Togo",
"Tokealau",
"Tonga",
"Trinidad and Tobago",
"Tunisia",
"Turkey",
"Turkmenistan",
"Turks and Caicos Island",
"Tuvalu",
"Uganda",
"Ukraine",
"United Arab Emirates",
"United Kingdom",
"United Republic of Tanzania",
"United States of America",
"United States Virgin Islands",
"Uruguay",
"Uzbekistan",
"Vanuatu",
"Vatican",
"Venezuela (Bolivarian Republic of)",
"Viet Nam",
"Wallis and Futuna",
"Western Sahara",
"Yemen",
"Zambia",
"Zimbabwe"
];

export const dataEntryDefault = [
  {
    key: '1',
    value:[
      {key: 'M0', value: '0'},
      {key: 'M1', value: '0'},
      {key: 'M2', value: '0'},
      {key: 'M3', value: '0'},
      {key: 'M4', value: '0'},
      {key: 'M5', value: '0'},
      {key: 'M6', value: '0'},
      {key: 'M7', value: '0'},
      {key: 'M8', value: '0'},
      {key: 'M9', value: '0'},
      {key: 'M10', value: '0'},
      {key: 'M11', value: '0'}
    ]
  },
  {
    key: '2',
    value:[
      {key: 'M12', value: '0'},
      {key: 'M13', value: '0'},
      {key: 'M14', value: '0'},
      {key: 'M15', value: '0'},
      {key: 'M16', value: '0'},
      {key: 'M17', value: '0'},
      {key: 'M18', value: '0'},
      {key: 'M19', value: '0'},
      {key: 'M20', value: '0'},
      {key: 'M21', value: '0'},
      {key: 'M22', value: '0'},
      {key: 'M23', value: '0'}
    ]
  },
  {
    key: '3',
    value:[
      {key: 'M24', value: '0'},
      {key: 'M25', value: '0'},
      {key: 'M26', value: '0'},
      {key: 'M27', value: '0'},
      {key: 'M28', value: '0'},
      {key: 'M29', value: '0'},
      {key: 'M30', value: '0'},
      {key: 'M31', value: '0'},
      {key: 'M8', value: '0'},
      {key: 'M32', value: '0'},
      {key: 'M33', value: '0'},
      {key: 'M34', value: '0'},
      {key: 'M35', value: '0'}
    ]
  },

];

export const ListTitle1 = [
  {
    title: "Total for the breastfeeding period",
    note: "",
  },
  {
    title: "Milk volume",
    note: "in Liters",
  },
  {
    title: "Value",
    note: "in USD",
  },
  {
    title: "Value",
    note: "in local currency",
  },
];

export const individualCalculatorOverViewF = "This calculator estimates the volume and value of breastmilk a mother produces based on the breastfeeding duration.";
export const individualCalculatorOverViewL = "Potential users are individual caregivers and breastfeeding supporters.";

export const countryCalculatorOverViewF = "This calculator estimates the volume and value of breastmilk produced each year and the loss due to suboptimal breastfeeding practices in each country and globally.";
export const countryCalculatorOverViewL = "Potential users of this tool are policymakers, influencers, project managers, researchers, national accountants, statisticians, caregivers, and other persons supporting breastfeeding.";