/*eslint-disable*/
import React from "react";
import { Flex, Img, Link, List, ListItem, Text, useColorModeValue, useColorMode } from "@chakra-ui/react";

import PropTypes from "prop-types";
import svg1 from "assets/svg/svg1.svg";
import svg2 from "assets/svg/svg2.svg";
import svg3 from "assets/svg/svg3.svg";
import svg4 from "assets/svg/svg4.svg";
import svg5 from "assets/svg/svg5.svg";
import img6 from "assets/img/img1.png";
import img7 from "assets/img/img2.png";
import img8 from "assets/img/img3.png";

export default function Footer(props) {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex id="page-footer" direction="row" justifyContent="center" mb="40px" flexWrap="wrap" flexDirection={{ lg: "row", sm: "column" }} alignItems="center">
      <Link target='_blank' href='https://www.fhisolutions.org/innovation-incubator/'>
        <Img display={colorMode === 'light' ? 'block' : 'none'} m="20px" id="ft-1" height="60px" src={svg2} />
      </Link>
      <Link target='_blank' href='https://www.fhisolutions.org'>
        <Img display={colorMode === 'light' ? 'block' : 'none'} m="20px" id="ft-2" height="60px" src={img7} />
      </Link>
      <Link target='_blank' href='https://www.aliveandthrive.org/en'>
        <Img display={colorMode === 'light' ? 'block' : 'none'} m="20px" id="ft-3" height="60px" src={svg1} />
      </Link>
      <Link target='_blank' href='https://nceph.anu.edu.au/research/projects/valuing-breastfeeding-through-mothers-milk-tool'>
        <Img display={colorMode === 'light' ? 'block' : 'none'} m="20px" id="ft-4" height="60px" src={svg3} />
      </Link>
      <Link target='_blank' href='https://www.fhisolutions.org/innovation-incubator/'>
        <Img display={colorMode === 'dark' ? 'block' : 'none'} m="20px" id="ft-5" height="60px" src={svg5} />
      </Link>
      <Link target='_blank' href='https://www.fhisolutions.org'>
        <Img display={colorMode === 'dark' ? 'block' : 'none'} m="20px" id="ft-6" height="60px" src={img8} />
      </Link>
      <Link target='_blank' href='https://www.aliveandthrive.org/en'>
        <Img display={colorMode === 'dark' ? 'block' : 'none'} m="20px" id="ft-7" height="60px" src={svg4} />
      </Link>
      <Link target='_blank' href='https://nceph.anu.edu.au/research/projects/valuing-breastfeeding-through-mothers-milk-tool'>
        <Img display={colorMode === 'dark' ? 'block' : 'none'} m="20px" id="ft-8" height="60px" src={img6} />
      </Link>
    </Flex>
  );
}
