import { INIT_STATE } from "../../constants.js";
import { getLostMilkDatas, getType} from "../actions";

export default function lostMilkReducer(state = INIT_STATE.lostMilk, action) {
  switch (action.type) {
    case getType(getLostMilkDatas.getLostMilkRequest):
      console.log("request")
      return {
        ...state,
        isHideLostMilk: true
      };
    case getType(getLostMilkDatas.getLostMilkSuccess):
      console.log("success")
      return {
        ...state,
        data: action.payload,
        isHideLostMilk: false
      };
    case getType(getLostMilkDatas.getLostMilkError):
      console.log("error")
      return {
        ...state,
      };  
    default:
      return state;
  }
}
