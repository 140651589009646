// Chakra imports
import React from "react";
import {
  Flex,
  Grid,
  Button,
  Center,
  Select
} from "@chakra-ui/react";
import TableData from "./components/TableData";
import {countryState$, dataEntryState$, lostMilkState$, countryInfoState$} from '../../../redux/selectors';
import {data1, data2, data3} from '../../../variables/general';
import {useDispatch,useSelector} from 'react-redux';
import {useState} from "react";
import * as action from '../../../redux/actions';
import { useTranslation } from "react-i18next";
import { dataEntryDefault, productionTitle1,productionTitle2,productionTitle3,productionTitle4,productionTitle5,productionTitle6, productionData1} from "variables/general";

import ProductionAndValue from "./components/ProductionAndValue";

export default function Dashboard() {
  const { t } = useTranslation();
  const [countryValue, setCountry] = useState("");
  const dispatch = useDispatch();
  const countries = useSelector(countryState$);
  const dataentry = useSelector(dataEntryState$);
  const countryInfo = useSelector(countryInfoState$);
  const lostMilk = useSelector(lostMilkState$);
  React.useEffect(() => {
        dispatch(action.getCountries.getCountryRequest());
  },[dispatch]);

  var dataInput = dataEntryDefault;
  const getData = React.useCallback((data, key)=>{
    let item =dataInput.find(x => x.key === key)
    if (item != null){
      item.value = data;
    } else{
      let json  = {
        key:key,
        value:data
      }
      dataInput.push(json);
    }
  },[]);
  const sendData = React.useCallback((ISO) => {
    let strjson = '{"ISO":"' + ISO + '"';
    for (let i = 0; i < dataInput.length; i++){
      for (let j = 0; j < dataInput[i].value.length; j++){
        let item = dataInput[i].value[j];
        let itemstr = ',"' + item.key + '":' + item.value;
        strjson += itemstr;
      }
    }
    strjson += '}';
    let json = JSON.parse(strjson)
    dispatch(action.getDataEntryDatas.getDataEntryRequest(json));
    dispatch(action.getLostMilkDatas.getLostMilkRequest(ISO));
    dispatch(action.getCountryInfo.getCountryInfoRequest(ISO));
  },[])
  
  return (
    <Flex direction="column" pt={{ base: "120px", md: "100px" }}>
      <Grid
          templateColumns={{ sm: "1fr", lg: "1fr 1fr 1fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'>
        <Select placeholder={t("label.selectCountry")}
            h='40px'
            minW="200px"
            mr="10px"
            onChange={(e) =>setCountry(e.currentTarget.value)}
            fontSize="14px"
            value={countryValue == '' ? (lostMilk?.ISO == null ? 'AFG' : lostMilk.ISO) : countryValue}>
            {countries?.map((value, id) => {
              return (
                <option key={id} value={value.ISO}>{value.Country}</option>
              );
            })}
        </Select>
      </Grid>
      
      <Grid
        mt = "24px"
        templateColumns={{ sm: "1fr", lg: "1fr 1fr 1fr" }}
        templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
        gap='24px'
        mb="26px">
        <TableData
        captions={["Month", "Rate"]}
        data={data1}
        keydata = "1"
        getData = {getData}
        datadefault = {dataEntryDefault[0].value}
        />
        <TableData
          captions={["Month", "Rate"]}
          data={data2}
          keydata = "2"
          getData = {getData}
          datadefault = {dataEntryDefault[1].value}
        />
        <TableData
          captions={["Month", "Rate"]}
          data={data3}
          keydata = "3"
          getData = {getData}
          datadefault = {dataEntryDefault[2].value}
        />
      </Grid>
        <Center>
          <Button
            onClick={()=>sendData(countryValue)}
            background="#BE830E"
            size='lg'
            minW="200px"
            mb="20px">
              Calculate
          </Button>
      </Center>
      {dataentry?.total_3y != null &&
        <Grid mt="24px">
          <Grid
            mt="24px"
            templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
            templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}
          >
            <ProductionAndValue
              mgr = "12px"
              captions={productionTitle1}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={dataentry?.total_3y == null ? productionData1 : dataentry?.total_3y}
            />
            <ProductionAndValue
              mgl = "12px"
              captions={productionTitle1}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={lostMilk?.total_3y == null ? productionData1 : lostMilk?.total_3y}
            />
          </Grid>
          <Grid
            mt="24px"
            templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
            templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}
          >
            <ProductionAndValue
              mgr = "12px"
              captions={productionTitle2}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={dataentry?.total_0m_6m == null ? productionData1 : dataentry?.total_0m_6m}
            />
            <ProductionAndValue
              mgl = "12px"
              captions={productionTitle2}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={lostMilk?.total_0m_6m == null ? productionData1 : lostMilk?.total_0m_6m}
            />
          </Grid>
          <Grid
            mt="24px"
            templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
            templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}
          >
            <ProductionAndValue
              mgr = "12px"
              captions={productionTitle3}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={dataentry?.total_6m_24m == null ? productionData1 : dataentry?.total_6m_24m}
            />
            <ProductionAndValue
              mgl = "12px"
              captions={productionTitle3}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={lostMilk?.total_6m_24m == null ? productionData1 : lostMilk?.total_6m_24m}
            />
          </Grid>
          <Grid
            mt="24px"
            templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
            templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}
          >
            <ProductionAndValue
              mgr = "12px"
              captions={productionTitle4}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={dataentry?.total_1sty == null ? productionData1 : dataentry?.total_1sty}
            />
            <ProductionAndValue
              mgl = "12px"
              captions={productionTitle4}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={lostMilk?.total_1sty == null ? productionData1 : lostMilk?.total_1sty}
            />
          </Grid>
          <Grid
            mt="24px"
            templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
            templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}
          >
            <ProductionAndValue
              mgr = "12px"
              captions={productionTitle5}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={dataentry?.total_2ndy == null ? productionData1 : dataentry?.total_2ndy}
            />
            <ProductionAndValue
              mgl = "12px"
              captions={productionTitle5}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={lostMilk?.total_2ndy == null ? productionData1 : lostMilk?.total_2ndy}
            />
          </Grid>
          <Grid
            mt="24px"
            templateColumns={{ sm: "1fr", lg: "1.5fr 1.5fr" }}
            templateRows={{ sm: "repeat(2, 1fr)", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}
          >
            <ProductionAndValue
              mgr = "12px"
              captions={productionTitle6}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={dataentry?.total_3rdy == null ? productionData1 : dataentry?.total_3rdy}
            />
            <ProductionAndValue
              mgl = "12px"
              captions={productionTitle6}
              rate={lostMilk?.rate == null ? "" : lostMilk?.rate}
              source={lostMilk?.source == null ? "" : lostMilk?.source}
              currency = {countryInfo?.currency == null ? "" : countryInfo?.currency}
              data={lostMilk?.total_3rdy == null ? productionData1 : lostMilk?.total_3rdy}
            />
          </Grid>
        </Grid>
      }
    </Flex>
  );
}