// Chakra imports
import {
    Table,
    Tbody,
    Th,
    Thead,
    Tr,
    Td,
    Center,
    useColorModeValue,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card.js";
  import React from "react";
  
  const TableData = ({...props}) => {
    const textColor = useColorModeValue("#BE830E", "white");
    let arrData = props.datadefault;
    const onChangeInput = React.useCallback((key,value) => {
      let item =arrData.find(x => x.key === key)
      if (item != null){
        item.value = value;
      } else{
        let obj = 
        {
          key:key,
          value:value
        };
        arrData.push(obj);
      }
      props.getData(arrData, props.keydata);
    },[]);

    return (
      <Card overflowX={{ sm: "scroll", xl: "hidden" }}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              {props.captions.map((caption, idx) => {
                return (
                  <Th key={idx} textTransform="none" fontSize="14px" color={textColor} ps={idx === 0 ? "0px" : null}>
                    <Center>
                        {caption}
                    </Center>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {props.data?.map((row, id) => {
              return (
                <Tr key={id} fontSize="14px">
                  <Td pl="0px"><Center>{row?.year}</Center></Td>
                   <Td>
                    <Center>{/*<Input type="number" min="0" max="100" onChange={(e)=>onChangeInput(row?.data,e.target.value)} width='auto' minW="100px" maxWidth='150px'/>*/}
                    <NumberInput onChange={(e)=>onChangeInput(row?.data,e)} defaultValue={0} min={0} max={100}>
                      <NumberInputField />
                      <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                      </NumberInputStepper>
                    </NumberInput>
                    </Center>
                  </Td> 
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Card>
    );
  };
  
  export default TableData;
  