import { Grid, Center, Image, Text, Box, Link } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";
import Capture from "assets/img/Capture.PNG";

const InfoTool = () => {
  return (
    <Card>
      <CardBody mt={{sm: "0px", lg: "20px"}}>
      <Grid
            templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
            templateRows={{ sm: "1fr", lg: "1fr" }}
            gap='24px'
            mb={{ lg: "26px" }}>
            <Image src={Capture} alt='img2' order={{sm: "1", lg: "1"}}/>
            <Box fontSize="15px" order={{sm: "2", lg: "2"}}>
                <Text>This tool highlights mothers' economic contribution to the family and society through breastfeeding.</Text>
                <Text mt="10px">Potential users of this tool are policymakers, influencers, project managers, researchers, national accountants, statisticians, caregivers, and other persons supporting breastfeeding.</Text>
                <Text mt="10px">This tool was developed by the 
                  <Link color='#BE830E' target="_blank" href='https://nceph.anu.edu.au/research/projects/valuing-breastfeeding-through-mothers-milk-tool'> Australia National University</Link> and
                  <Link color='#BE830E' target="_blank" href='https://www.aliveandthrive.org'> Alive & Thrive</Link> with support from the
                  <Link color='#BE830E' target="_blank" href='https://www.fhisolutions.org/innovation-incubator/'> FHI Solutions Innovation Incubator</Link>.
                </Text>
            </Box>
            {/* <Center order={{sm: "1", lg: "2"}}><Image src={Capture} alt='img2' order={{sm: "1", lg: "2"}}/></Center> */}
        </Grid>
      </CardBody>
    </Card>
  );
};

export default InfoTool;
