import { INIT_STATE } from "../../constants.js";
import { getIndividualDatas, getType} from "../actions";

export default function dataEntryReducer(state = INIT_STATE.dataEntry, action) {
  switch (action.type) {
    case getType(getIndividualDatas.getIndividualRequest):
      console.log("request")
      return {
        ...state,
        isHideIndividual: true
      };
    case getType(getIndividualDatas.getIndividualSuccess):
      console.log("success")
      return {
        ...state,
        data: action.payload,
        isHideIndividual: false
      };
    case getType(getIndividualDatas.getIndividualError):
      console.log("error")
      return {
        ...state,
      };  
    default:
      return state;
  }
}
