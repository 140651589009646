// Chakra imports
import {
    Table,
    Tbody,
    Text,
    Th,
    Thead,
    Tr,
    Td,
    Center,
    useColorModeValue,
  } from "@chakra-ui/react";
  import Card from "components/Card/Card.js";
  import CardBody from "components/Card/CardBody";
  import CardHeader from "components/Card/CardHeader";
  import React from "react";
  
  const ProductionAndValue = ({captions, data, rate, source, currency, header }) => {
  const textColor = useColorModeValue("#BE830E", "white");
  
    return (
      <Card className='table-country-caculator' overflowX={{ sm: "scroll", xl: "hidden" }} mb="24px">
        {/* <CardHeader mb="14px">
          <Text fontWeight="bold">{header}</Text>
        </CardHeader> */}
        <Table variant='simple' mb="20px">
          <Thead>
            <Tr>
              {captions.map((caption, idx) => {
                return (
                  <Th key={idx} pl={idx == 0 ? '0px' : null} textTransform="none" whiteSpace="nowrap">
                    { idx == 0 ?
                      (
                        <Text fontSize="14px" color='#BE830E' >{idx == 0 ? header : caption.title}</Text>
                      )
                      :
                      (
                        <Center><Text fontSize="14px" color='#BE830E' >{idx == 0 ? header : caption.title}</Text></Center>
                      )
                    }
                    <Center height='16px'><Text fontSize="12px" fontWeight="light"> {idx == 3 ? 'in million ' + currency : caption.note}</Text></Center>
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((row, id) => {
              return (
                <Tr key={id} fontSize="14px">
                  <Td pl="0px" whiteSpace="nowrap">{row.id}</Td>
                  <Td><Center>{row.volume =='NaN%' ? 'N/A' : (row.volume.toLocaleString())}</Center></Td>
                  <Td><Center>{row.valueUSD?.toLocaleString()}</Center></Td>
                  <Td><Center>{row.valueAustralianDolor?.toLocaleString()}</Center></Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* <Center><Text fontWeight="light" fontSize="12px">Exchange rate US$ 1: {rate} {currency}</Text></Center>
        <Center><Text fontWeight="light" fontSize="12px">Data source: {source}</Text></Center> */}
      </Card>
    );
  };
  
  export default ProductionAndValue;
  