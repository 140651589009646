export const countryState$ = (state) => state.countries?.data;
export const hideDataCountry$ = (state) => state.countries?.isHide;
export const loadingDataCountry$ = (state) => state.countries?.isLoading;
export const isDisableBtnSearch$ = (state) => state.countries?.isDisableBtnSearch;

export const breastfeedingState$ = (state) => state.breastfeedingDataReducer?.data;

export const breastfeedingChartState$ = (state) => state.breastfeedingChartDataReducer?.data;
export const isHideChart$ = (state) => state.breastfeedingChartDataReducer?.isHideChart;

export const countryInfoState$ = (state) => state.countryInfoReducer?.data;
export const isHideDataInfo$ = (state) => state.countryInfoReducer?.isHideDataInfo;


export const lostMilkState$ = (state) => state.lostMilkReducer?.data;
export const isHideLostMilk$ = (state) => state.lostMilkReducer?.isHideLostMilk;

export const dataEntryState$ = (state) => state.dataEntryReducer?.data;

export const individualState$ = (state) => state.individualReducer?.data;
export const isHideIndividual$ = (state) => state.individualReducer?.isHideIndividual;