import {
  Heading,
  Box,
  Text,
  Grid,
  Link,
  Center,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  Image,
} from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import ChildAndDad from "assets/img/ChildrenAndDad.jpg";
import React from "react";

const AboutToolItem = ({ title, link }) => {
  return (
    <Card className="introduction-card">
      <Grid spacing={16} justify="flex-start">
        <Grid order={{ sm: "2", lg: "2" }}>
          <Link href={link}>
            <Heading as="h3" size="lg">
              {title}
            </Heading>
          </Link>
        </Grid>
        <Grid
          order={{ sm: "1", lg: "1" }}
          sx={{ display: { lg: "none", sm: "block" } }}
          mt="20px"
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap="24px"
          mb={{ sm: "26px" }}
        >
          <Link href={link}>
            <Image
              src={ChildAndDad}
              alt="img2"
              sx={{ display: { sm: "block", lg: "none" } }}
            />
          </Link>
        </Grid>
      </Grid>
      <CardBody mt={{ sm: "0px", lg: "30px" }}>
        <Grid
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap="24px"
          mb={{ lg: "26px" }}
        >
          <Link href={link}>
            <Image
              src={ChildAndDad}
              alt="img2"
              sx={{ display: { sm: "none", lg: "block" } }}
            />
          </Link>
          <Box fontSize="15px">
            <Text>
              Read the{" "}
              <Link
                color="#BE830E"
                target="_blank"
                href="https://nceph.anu.edu.au/files/Mothers_Milk_Tool_Information_Kit.pdf#overlay-context=news-events/events/measuring-and-valuing-womens-productivity-mothers-milk-tool"
              >
                Brief
              </Link>
            </Text>
            <Text>
              Attend{" "}
              <Link
                color="#BE830E"
                target="_blank"
                href="https://nceph.anu.edu.au/news-events/events/measuring-and-valuing-womens-productivity-mothers-milk-tool"
              >
                Webinar
              </Link>
            </Text>
            <Text>
              Read the{" "}
              <Link
                color="#BE830E"
                target="_blank"
                href="https://www.aliveandthrive.org/en/news/mothers-milk-contributes-almost-4-trillion-to-the-global-economy-but-its-value-has-never-been"
              >
                Commentary
              </Link>
            </Text>
            <Text>
              Follow{" "}
              <Link
                color="#BE830E"
                target="_blank"
                href="https://twitter.com/hashtag/MothersMilkTool?src=hashtag_click"
              >
                #MothersMilkTool
              </Link>
            </Text>
            <Text>
              Download{" "}
              <Link
                color="#BE830E"
                target="_blank"
                href="https://nceph.anu.edu.au/research/projects/valuing-breastfeeding-through-mothers-milk-tool"
              >
                Offline version
              </Link>
            </Text>
            <Text>
              Read papers:
              <br />
              <Link
                color="#BE830E"
                target="_blank"
                href="https://www.frontiersin.org/articles/10.3389/fpubh.2023.1152659/full"
              >
                - The volume and monetary value of human milk produced by the
                world's breastfeeding mothers: Results from a new tool
              </Link>
              <br />
              <Link
                color="#BE830E"
                target="_blank"
                href="https://www.ennonline.net/fex/72/human-milk-a-win-win-win-for-health-sustainability-and-economics"
              >
                - Human milk: A win-win-win for health, sustainability, and
                economics?
              </Link>
            </Text>
          </Box>
        </Grid>
      </CardBody>
    </Card>
  );
};

export default AboutToolItem;
