// Chakra imports
import { useColorModeValue, Heading, Text, Image, Grid, Box } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";
import { individualCalculatorOverViewF, individualCalculatorOverViewL } from "../../../../variables/general";
import img from "assets/img/IndividualCalculator.jpg";


const IndividualCountry = () => {
  return (
    <Card mb="10px">
      <Grid spacing={16} justify="flex-start">
        <Grid order={{ sm: "2", lg: "2" }} mb={{ sm: "26px" }}>
          <Heading as='h3' size='lg'>Individual calculator</Heading>
        </Grid>
        <Grid
          order={{ sm: "1", lg: "1" }}
          sx={{ display: { lg: 'none', sm: 'block' } }}
          mt="20px"
          templateColumns={{ sm: "1fr", lg: "1.3fr 1.7fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'
          mb={{ sm: "26px" }}>
          <Image src={img} alt='img2' order={{ sm: "1", lg: "1" }} sx={{ display: { sm: 'block', lg: 'none' } }} />
        </Grid>
      </Grid>
      <CardBody>
        <Grid
          templateColumns={{ sm: "1fr", lg: "0.4fr 1.6fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'>
          <Image src={img} alt='img2' sx={{ display: { sm: 'none', lg: 'block' } }} />
          <Box fontSize="15px" order={{ sm: "2", lg: "2" }}>
            <Text>{individualCalculatorOverViewF}</Text>
            <Text mt="10px">{individualCalculatorOverViewL}</Text>
          </Box>
        </Grid>
        {/* <Grid
          mt='30px'
          templateColumns={{ sm: "1fr", lg: "0.4fr 1.6fr" }}
          templateRows={{ sm: "1fr", lg: "1fr" }}
          gap='24px'>
          <Image src={img} alt='img2' />
          <Box fontSize="15px" order={{ sm: "2", lg: "2" }}>
            <Text>{individualCalculatorOverViewF}</Text>
            <Text mt="10px">{individualCalculatorOverViewL}</Text>
          </Box>
        </Grid> */}
      </CardBody>
    </Card>
  );
};

export default IndividualCountry;
