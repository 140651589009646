import { INIT_STATE } from "../../constants.js";
import { getBreastfeedingDatas, getType} from "../actions";

export default function breastfeedingDataReducer(state = INIT_STATE.breastfeedings, action) {
  switch (action.type) {
    case getType(getBreastfeedingDatas.getBreastfeedingDataRequest):
      console.log("request")
      return {
        ...state,
      };
    case getType(getBreastfeedingDatas.getBreastfeedingDataSuccess):
      console.log("success")
      return {
        ...state,
        data: action.payload,
      };
    case getType(getBreastfeedingDatas.getBreastfeedingDataError):
      console.log("error")
      return {
        ...state,
      };  
    default:
      return state;
  }
}
