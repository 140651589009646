import { INIT_STATE } from "../../constants.js";
import { getBreastfeedingChartDatas, getType} from "../actions";

export default function breastfeedingChartDataReducer(state = INIT_STATE.breastfeedingchart, action) {
  switch (action.type) {
    case getType(getBreastfeedingChartDatas.getBreastfeedingChartDataRequest):
      console.log("request")
      return {
        ...state,
        isHideChart : true
      };
    case getType(getBreastfeedingChartDatas.getBreastfeedingChartDataSuccess):
      console.log("success")
      return {
        ...state,
        data: action.payload,
        isHideChart : false
      };
    case getType(getBreastfeedingChartDatas.getBreastfeedingChartDataError):
      console.log("error")
      return {
        ...state,
      };  
    default:
      return state;
  }
}
