import React, {useState} from "react";
import ReactApexChart from "react-apexcharts";
import { lineChartData, lineChartOptions } from "variables/charts";
import {
  Box, Center, Text,
} from "@chakra-ui/react";
import {breastfeedingChartState$} from '../../redux/selectors';
import {useDispatch,useSelector} from 'react-redux';

const LineChart = ({data}) => {
  return (
    <Box minW="600px" height="95%">
      <ReactApexChart
        // options={data?.linechartoptions == null ? lineChartOptions : data?.linechartoptions}
        options = {lineChartOptions}
        series={data?.linechartdata == null ? lineChartData : data?.linechartdata}
        type="area"
        width="100%"
        height="100%"
      />
      <Center color="#BE830E">Months</Center>
    </Box>
  );
}
export default LineChart;


