import { INIT_STATE } from "../../constants.js";
import { getCountries, getType, isShow, isHide, isLoading} from "../actions";

export default function countryReducer(state = INIT_STATE.countries, action) {
  switch (action.type) {
    case getType(getCountries.getCountryRequest):
      console.log("request")
      return {
        ...state,
        isDisableBtnSearch: true
      };
    case getType(getCountries.getCountrySuccess):
      console.log("success")
      return {
        ...state,
        data: action.payload,
        isDisableBtnSearch: false
      };
    case getType(getCountries.getCountryError):
      console.log("error")
      return {
        ...state,
      };
    case getType(isShow):
      return {
        ...state,
        isHide: true,
      };
    case getType(isHide):
      return {
        ...state,
        isHide: false,
      };
    case getType(isLoading):
      return {
        ...state,
        isLoading: !state.isLoading,
      };
    default:
      return state;
  }
}
