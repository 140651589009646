import React, { useState, useEffect  } from "react";
import Poll from "react-polls";
//import Iframe from './components/iframe.js';
// import Iframe from 'react-iframe'
import { Heading , TabList, TabPanels, Tab, TabPanel, Box, Hide, Center, Grid, Link } from '@chakra-ui/react'
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
  const Fakepolls = () => {
  // Setting answers to state to reload the component with each vote
  // const pollStylesBlack = {
  //   questionSeparator: false,
  //   questionSeparatorWidth: 'question',
  //   questionBold: true ,
  //   questionColor: '#BE830E',
  //   align: 'center',
  //   theme: 'white'
  // };
  // const pollStylesWhite = {
  //   questionSeparator: false,
  //   questionSeparatorWidth: 'question',
  //   questionBold: true ,
  //   questionColor: '#BE830E',
  //   align: 'center',
  //   theme: 'black'
  // }
  // const pollQuestion = "Do you agree with the content of our website?";
  // let answers = [ // <-- renamed to avoid collision with state variable
  //   { option: "Agree", votes: 7 },
  //   { option: "Disagree", votes: 1 },
  //   { option: "Don't know", votes: 2 }
  // ];
  // const [pollAnswers, setPollAnswers] = useState([...answers]);
  // const { colorMode, toggleColorMode } = useColorMode();
  // const handleVote = React.useCallback((voteAnswer) => {
  //   setPollAnswers((pollAnswers) =>
  //     pollAnswers.map((answer) =>
  //       answer.option === voteAnswer
  //         ? {
  //             ...answer,
  //             votes: answer.votes + 1
  //           }
  //         : answer
  //     )
  //   );
  //   console.log(pollAnswers);
  // },[]);

  // return (
  //   <Grid
  //       mt="100px"
  //       gap='24px'
  //       mb={{ lg: "26px" }}>
  //       <Poll
  //           question={pollQuestion}
  //           answers={pollAnswers}
  //           onVote={handleVote}
  //           customStyles={colorMode === 'light' ? pollStylesWhite : pollStylesBlack}
  //           noStorage={false}
  //       />
  //   </Grid>
  // );

  return (
    // <Box mt="100px">
    //   <Tabs variant='enclosed' w="100%">
    //     <TabList>
    //       <Tab>Feedback</Tab>
    //       <Tab>Statistics</Tab>
    //     </TabList>
    //     <TabPanels>
    //       <TabPanel>
    //         <Box>
    //           <Center>
    //             <iframe id="feedback" width="1000px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URFFBQlhWOE0zMjBJOTk0MFU2UTFSR0g3Mi4u"></iframe>            
    //           </Center>
    //         </Box>
    //       </TabPanel >
    //       <TabPanel>
    //         <Box>
    //           <Center>
    //             <iframe id="statistics" height="1710px" width="900px" src="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=4FVJcKKaXYgkKuiXNN4wef12iEza0ILV&id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URFFBQlhWOE0zMjBJOTk0MFU2UTFSR0g3Mi4u"></iframe>
    //           </Center>
    //         </Box>
    //       </TabPanel>
    //     </TabPanels>
    //   </Tabs>
    // </Box>
    <Card mt="100px">
      <CardHeader>
        <Heading as='h4' size='md' mb="5px">
          Feedback
        </Heading>
      </CardHeader>
      <CardBody>
        <Grid>
          <Link color='#BE830E' target="_blank" href='https://forms.office.com/Pages/ResponsePage.aspx?id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URFFBQlhWOE0zMjBJOTk0MFU2UTFSR0g3Mi4u'>Give your feedback</Link>
          <Link color='#BE830E' target="_blank" href="https://forms.office.com/Pages/AnalysisPage.aspx?AnalyzerToken=4FVJcKKaXYgkKuiXNN4wef12iEza0ILV&id=32quJr5hP0SNxGUxthqaOKNuX5OvLmZOssjGlr4FJL1URFFBQlhWOE0zMjBJOTk0MFU2UTFSR0g3Mi4u">Feedback from other users</Link>
          <Link color='#BE830E' target="_blank" href='https://www.aliveandthrive.org/en/contact-us'>Contact us</Link>
        </Grid>
      </CardBody>
    </Card>
  );
  
};
export default Fakepolls;

class IFFeedback extends React.Component {
    render() {
      return <div id="ff-feedback"></div>;
    }
    componentDidMount(){
      var script = document.createElement("script");
      script.id = "ff-script-feedback";
      script.src = "https://formfacade.com/include/116534090482997173125/form/1FAIpQLSe8w1cX4V-k-eCYIqLm7mXZ0e_tu5SH8Ij4uGBVbFipuvhQBw/classic.js?div=ff-feedback";
      script.defer = true;
      script.async = true;
      document.body.appendChild(script);
    }
  }